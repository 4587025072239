var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message ? _c('div', {
    staticClass: "message-notification"
  }, [_c('div', {
    staticClass: "message-notification__title"
  }, [_vm._v("\n    Новое сообщение\n    "), _c('button', {
    staticClass: "message-notification__close",
    on: {
      "click": _vm.close
    }
  }, [_c('close-icon')], 1)]), _vm._v(" "), _c('div', {
    staticClass: "message-notification__user"
  }, [_vm._v("\n    " + _vm._s(_vm.message.sender.login) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "message-notification__message"
  }, [_vm._v("\n    " + _vm._s(_vm.message.message) + "\n  ")])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }