/**
 *
 * @param {import('@nuxtjs/axios').NuxtAxiosInstance} $axios
 * @param $auth
 * @param $toast
 * @param redirect
 */
export default function ({$axios, $auth, $toast, redirect}) {
  $axios.onError((error) => {

    const {url, method } = error?.response?.config || {};

    if (error.response.status === 401) {
      $auth.logout();

      redirect('/login')
    }

    if (error.response.data?.code === 'ban') {
      $toast.error(`Вам ограничен доступ!`  + (
          error.response.data?.reason
          ? ' Причина: ' + error.response.data?.reason
          : '')
      )
      redirect('/')
      $auth.logout();
      return Promise.reject();
    }

    if (error.response.status === 403) {
      $toast.error(`Недостаточно прав для выполнения ${method} ${url}`)
      return Promise.reject(`403 error: No right to ${method} ${url}`)
    }

    if (error.response.status === 500) {
      $toast.error(`Ошибка сервера при выполнении ${method} ${url}. Попробуйте позже`)
      return Promise.reject(`Server error for ${method} ${url}`)
    }
  })
}
