//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Illustration from 'assets/svg/404.svg?inline'
import getMeta from "@/utils/getMeta"
export default {
  name: "ErrorPage",
  components: {
    Illustration
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return getMeta({
      title: this.notFound ? 'Страница не найдена' : 'Ошибка',
      description: 'Похоже эта страница была удалена с сайта или заменена новой. Перейдите на главную или воспользуйтесь поиском',
    })
  },
  computed: {
    notFound() {
      return this.error.statusCode === 404
    }
  },
}
