import { render, staticRenderFns } from "./pageHeader.vue?vue&type=template&id=c0734bba&scoped=true&"
import script from "./pageHeader.vue?vue&type=script&lang=js&"
export * from "./pageHeader.vue?vue&type=script&lang=js&"
import style0 from "assets/sass/components/_header.sass?vue&type=style&index=0&id=c0734bba&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0734bba",
  null
  
)

/* custom blocks */
import block0 from "./pageHeader.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CitySelector: require('/builds/bookcrossing/front/components/citySelector.vue').default})
