export const state = () => ({
  /** @type IBook */
  selectedBook: null,
})

export const mutations = {
  SET_SELECTED_BOOK(state, book) {
    state.selectedBook = book
  },
}

export const actions = {
  async setSelectedBook({ commit }, book) {
    commit("SET_SELECTED_BOOK", book)
  },
}
