//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageHeader from 'components/pageHeader'
import pageFooter from 'components/pageFooter'
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  name: 'DefaultLayout',
  components: { pageHeader,pageFooter },
  computed: {
    ...mapState('city', ['city'])
  },
  created() {
    this.$root.$on('scrollTop', () => this.$refs.wrapper.scrollIntoView());
  },
  beforeMount() {


    if (this.$cookies.get('language')) {
      this.$i18n.setLocale(this.$cookies.get('language'))
    } else {
      this.$i18n.setLocale(this.city.country.language)
    }

    this.$OneSignal.init({
      appId: this.$config.oneSignalAppId,
      promptOptions: {
        slidedown: {
          enabled: true,
          autoPrompt: true,
          timeDelay: 20,
          pageViews: 3,
          /* These prompt options values configure both the HTTP prompt and the HTTP popup. */
          /* actionMessage limited to 90 characters */
          actionMessage: "Хотите получать уведомления о новых книгах в вашем городе?",
          /* acceptButtonText limited to 15 characters */
          acceptButtonText: "Да",
          /* cancelButtonText limited to 15 characters */
          cancelButtonText: "Нет",
        }
      },
      welcomeNotification: {
        title: "Буккроссинг",
        message: "Готово! Когда кто-то отпустит книгу вы увидите такое уведомление"
      }
    }).then(() => {
      if (this.$auth.loggedIn) {
        this.$OneSignal.setExternalUserId(this.$auth.user.id);
      }

      this.$OneSignal.on('notificationPermissionChange', function (permissionChange) {
        let currentPermission = permissionChange.to;
        //window.ym('67525561', 'reachGoal', 'push_subscribe', {state: currentPermission});
      });

    });
  },
  mounted() {
    if (this.$auth.loggedIn) {
      this.$echo.private('im.' + this.$auth.user.id)
        .on('message', (message) => {
          this.getDialogs();
          this.SET_NEW_MESSAGE(message);
        });
    }
  },
  methods: {
    ...mapActions('messages', ['getDialogs']),
    ...mapMutations('messages', ['SET_NEW_MESSAGE']),
  }
}
