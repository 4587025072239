//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from "vuex"
import closeIcon from "assets/svg/close.svg?inline"
export default {
  name: "MessageNotification",
  components: {
    closeIcon
  },
  computed: {
    ...mapState('messages', {
      message: state => state.newMessage
    })
  },
  methods: {
    ...mapMutations('messages', ['SET_NEW_MESSAGE']),
    close() {
      this.SET_NEW_MESSAGE(null)
    }
  }
}
