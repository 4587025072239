//
//
//
//
//
//

export default {
  name: 'VkCommunity',
  props: {
    /** Community page identifier */
    id: {
      type: Number,
      required: true
    },
  },
  mounted() {
    this.injectVKOpenApi().then(() => {
      VK.Widgets.Group("city-vk-community", {mode: 3, no_cover: 1, width: 'auto'}, this.id);
    }).catch(e => {
      console.error(e)
    });
  },
  methods: {
    injectVKOpenApi() {
      return new Promise((resolve, reject) => {
        try {
          const fjs = document.getElementsByTagName('script')[0];

          if (document.getElementById('vk_openapi_js')) {
            resolve();
            return;
          }

          const js = document.createElement('script');
          js.id = 'vk_openapi_js';
          js.src = '//vk.com/js/api/openapi.js?152';
          js.onload = resolve;
          js.onerror = event => {
            reject(new Error(`Failed to load ${event.target.src}`));
          };

          fjs.parentNode.insertBefore(js, fjs);
        } catch (err) {
          reject(err);
        }
      });
    }
  }
}
