var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_vm.city ? _c('div', {
    staticClass: "city-selector",
    on: {
      "click": _vm.toggle
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$i18n.locale === 'en' ? _vm.city.title_en : _vm.city.title) + "\n  ")]) : _vm._e(), _vm._v(" "), _vm.displayConfirmation ? _c('transition', {
    attrs: {
      "name": "appearConfirmation",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "city-selection-confirm"
  }, [_c('div', {
    staticClass: "city-selection-confirm__city"
  }, [_vm._v("\n        " + _vm._s(_vm.city.title) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "city-selection-confirm__question"
  }, [_c('span', {
    staticClass: "city-selection-confirm__text"
  }, [_vm._v(_vm._s(_vm.$t('question')))]), _vm._v(" "), _c('button', {
    staticClass: "city-selection-confirm__yes",
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('yes')) + "\n        ")]), _vm._v(" "), _c('button', {
    staticClass: "city-selection-confirm__no",
    on: {
      "click": _vm.chose
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('no')) + "\n        ")])])])]) : _vm._e(), _vm._v(" "), _vm.isOpen ? _c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.close
    }
  }) : _vm._e(), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "appearList",
      "appear": ""
    }
  }, [_vm.isOpen ? _c('div', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.close,
      expression: "close"
    }],
    staticClass: "city-selection-block",
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.query,
      expression: "query"
    }, {
      name: "debounce",
      rawName: "v-debounce",
      value: _vm.loadCities,
      expression: "loadCities"
    }],
    staticClass: "city-selection-block__search",
    attrs: {
      "type": "search",
      "placeholder": _vm.$t('search')
    },
    domProps: {
      "value": _vm.query
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.query = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "city-selection-block__items"
  }, _vm._l(10, function (i) {
    return _c('div', {
      key: i,
      staticClass: "city-selection-block__item city-selection-block__item_placeholder skeleton"
    });
  }), 0) : [_vm.list ? _c('div', {
    staticClass: "city-selection-block__items"
  }, [_vm.$store.state.city.city ? _c('div', {
    staticClass: "city-selection-block__item city-selection-block__item_current"
  }, [_vm._v("\n            " + _vm._s(_vm.city.title) + "\n          ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.list, function (cityItem) {
    return _c('div', {
      key: cityItem.id,
      staticClass: "city-selection-block__item",
      attrs: {
        "title": cityItem.region.title,
        "tabindex": "0"
      },
      on: {
        "click": function ($event) {
          return _vm.select(cityItem.id);
        }
      }
    }, [_vm._v("\n            " + _vm._s(cityItem.title) + "\n          ")]);
  })], 2) : _c('div', {
    staticClass: "city-selection-block__space"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('empty')) + "\n        ")])]], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }