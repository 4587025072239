import { render, staticRenderFns } from "./pageFooter.vue?vue&type=template&id=4818812d&"
import script from "./pageFooter.vue?vue&type=script&lang=js&"
export * from "./pageFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./pageFooter.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VkCommunity: require('/builds/bookcrossing/front/components/vkCommunity.vue').default})
