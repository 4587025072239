import Vue from "vue"

export const state = () => ({
  /** @type IUser|null */
  recipient: null,
  drafts: {},
  dialogsLoading: false,
  /** @type IMessage[] */
  dialogs: [],
  dialogsPage: 1,
  dialogsPages: 1,
  /** @type IMessage|null */
  newMessage: null
})

export const mutations = {
  SET_RECIPIENT(state, recipient) {
    state.recipient = recipient
  },
  SET_DRAFT_MESSAGE(state, { userId, message }) {
    Vue.set(state.drafts, userId, message);
  },
  SET_DIALOGS_LOADING(state, loadingState) {
    state.dialogsLoading = loadingState
  },
  SET_DIALOGS(state, dialogs) {
    state.dialogs = dialogs
  },
  APPEND_DIALOGS(state, dialogs) {
    state.dialogs.push(...dialogs);
  },
  SET_DIALOGS_PAGE(state, dialogsPage) {
    state.dialogsPage = dialogsPage
  },
  SET_DIALOGS_PAGES(state, dialogsPages) {
    state.dialogsPages = dialogsPages
  },
  SET_NEW_MESSAGE(state, newMessage) {
    state.newMessage = newMessage
  },
}

export const actions = {
  setSelectedRecipient({ commit }, recipient) {
    commit("SET_RECIPIENT", recipient)
  },
  setDraft({ commit }, { userId, message }) {
    commit("SET_DRAFT_MESSAGE", { userId, message })
  },
  async getDialogs({ commit }) {
    commit("SET_DIALOGS_LOADING", true)
    try {
      const response = await this.$axios.$get("/messages");
      commit("SET_DIALOGS", response.data)
      commit("SET_DIALOGS_PAGE", response.pagination.currentPage + 1)
    } catch (e) {
      console.error(e)
      this.$toast.error("Не удалось загрузить список диалогов");
    }
    commit("SET_DIALOGS_LOADING", false)
  },
  /**
   * Mark all messages with user as read
   * @param _
   * @param {number} userId
   * @return {Promise<void>}
   */
  async markAsRead({ _ }, userId) {
    try {
      await this.$axios.$post("/messages/" + userId + "/read");
    } catch (e) {
      console.error(e)
    }
  },
}

export const getters = {
  getDraft: state => recipientId => {
    return state.drafts[recipientId] || '';
  }
}
