export const EmptyScreen = () => import('../../components/EmptyScreen.vue' /* webpackChunkName: "components/empty-screen" */).then(c => wrapFunctional(c.default || c))
export const LogPhotoModal = () => import('../../components/LogPhotoModal.vue' /* webpackChunkName: "components/log-photo-modal" */).then(c => wrapFunctional(c.default || c))
export const MessageNotification = () => import('../../components/MessageNotification.vue' /* webpackChunkName: "components/message-notification" */).then(c => wrapFunctional(c.default || c))
export const ProfileEditForm = () => import('../../components/ProfileEditForm.vue' /* webpackChunkName: "components/profile-edit-form" */).then(c => wrapFunctional(c.default || c))
export const ShelfPhotoUploadModal = () => import('../../components/ShelfPhotoUploadModal.vue' /* webpackChunkName: "components/shelf-photo-upload-modal" */).then(c => wrapFunctional(c.default || c))
export const ShelfPhotosModal = () => import('../../components/ShelfPhotosModal.vue' /* webpackChunkName: "components/shelf-photos-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthorCard = () => import('../../components/authorCard.vue' /* webpackChunkName: "components/author-card" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const BooksStack = () => import('../../components/booksStack.vue' /* webpackChunkName: "components/books-stack" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const CitySelector = () => import('../../components/citySelector.vue' /* webpackChunkName: "components/city-selector" */).then(c => wrapFunctional(c.default || c))
export const Cover = () => import('../../components/cover.vue' /* webpackChunkName: "components/cover" */).then(c => wrapFunctional(c.default || c))
export const GuestNotification = () => import('../../components/guestNotification.vue' /* webpackChunkName: "components/guest-notification" */).then(c => wrapFunctional(c.default || c))
export const JailForm = () => import('../../components/jailForm.vue' /* webpackChunkName: "components/jail-form" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LogItem = () => import('../../components/logItem.vue' /* webpackChunkName: "components/log-item" */).then(c => wrapFunctional(c.default || c))
export const LogMore = () => import('../../components/logMore.vue' /* webpackChunkName: "components/log-more" */).then(c => wrapFunctional(c.default || c))
export const Observer = () => import('../../components/observer.vue' /* webpackChunkName: "components/observer" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/pageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/pageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const QueueForm = () => import('../../components/queueForm.vue' /* webpackChunkName: "components/queue-form" */).then(c => wrapFunctional(c.default || c))
export const Rating = () => import('../../components/rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const RegisteredModal = () => import('../../components/registeredModal.vue' /* webpackChunkName: "components/registered-modal" */).then(c => wrapFunctional(c.default || c))
export const ReleaseForm = () => import('../../components/releaseForm.vue' /* webpackChunkName: "components/release-form" */).then(c => wrapFunctional(c.default || c))
export const Share = () => import('../../components/share.vue' /* webpackChunkName: "components/share" */).then(c => wrapFunctional(c.default || c))
export const ShelfReview = () => import('../../components/shelfReview.vue' /* webpackChunkName: "components/shelf-review" */).then(c => wrapFunctional(c.default || c))
export const StatFilters = () => import('../../components/statFilters.vue' /* webpackChunkName: "components/stat-filters" */).then(c => wrapFunctional(c.default || c))
export const VkCommunity = () => import('../../components/vkCommunity.vue' /* webpackChunkName: "components/vk-community" */).then(c => wrapFunctional(c.default || c))
export const WatchForm = () => import('../../components/watchForm.vue' /* webpackChunkName: "components/watch-form" */).then(c => wrapFunctional(c.default || c))
export const IndexAbout = () => import('../../components/index/about.vue' /* webpackChunkName: "components/index-about" */).then(c => wrapFunctional(c.default || c))
export const IndexFinds = () => import('../../components/index/finds.vue' /* webpackChunkName: "components/index-finds" */).then(c => wrapFunctional(c.default || c))
export const IndexIntro = () => import('../../components/index/intro.vue' /* webpackChunkName: "components/index-intro" */).then(c => wrapFunctional(c.default || c))
export const IndexLatestNews = () => import('../../components/index/latestNews.vue' /* webpackChunkName: "components/index-latest-news" */).then(c => wrapFunctional(c.default || c))
export const IndexReleases = () => import('../../components/index/releases.vue' /* webpackChunkName: "components/index-releases" */).then(c => wrapFunctional(c.default || c))
export const IndexStats = () => import('../../components/index/stats.vue' /* webpackChunkName: "components/index-stats" */).then(c => wrapFunctional(c.default || c))
export const MesagesDialogs = () => import('../../components/mesages/dialogs.vue' /* webpackChunkName: "components/mesages-dialogs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
