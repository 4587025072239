var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header__main"
  }, [_c('NuxtLink', {
    staticClass: "header-logo",
    attrs: {
      "to": "/"
    }
  }, [_c('logo', {
    staticClass: "header-logo__image"
  }), _vm._v(" "), _c('span', {
    staticClass: "header-logo__text"
  }, [_vm._v(_vm._s(_vm.$t('logo_text')))])], 1), _vm._v(" "), _c('div', {
    staticClass: "city-selector-wrapper",
    attrs: {
      "id": "city-selector"
    }
  }, [_c('city-selector')], 1)], 1), _vm._v(" "), _c('form', {
    staticClass: "header__search",
    attrs: {
      "method": "post",
      "action": "/"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleSearch.apply(null, arguments);
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    staticClass: "header__search-field",
    attrs: {
      "name": "query",
      "type": "search",
      "placeholder": _vm.$t('search_placeholder')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _vm._v(" "), _c('button', {
    staticClass: "header__search-button",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('find')) + "\n    ")])]), _vm._v(" "), _vm.$auth.loggedIn ? _c('NuxtLink', {
    staticClass: "header__new-book-button",
    attrs: {
      "to": "/books/registration"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.$t('register')) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "header__user-block"
  }, [_vm.$auth.loggedIn ? _c('div', {
    staticClass: "header-user",
    on: {
      "click": function ($event) {
        _vm.userMenuOpened = true;
      },
      "mouseenter": function ($event) {
        _vm.userMenuOpened = true;
      }
    }
  }, [_c('img', {
    staticClass: "avatar header-user__avatar",
    attrs: {
      "alt": "",
      "src": _vm.$auth.user.avatar,
      "loading": "lazy"
    }
  }), _vm._v(" "), _c('down-icon', {
    staticClass: "header-user__down-icon"
  })], 1) : _vm._e(), _vm._v(" "), _vm.$auth.loggedIn ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.userMenuOpened,
      expression: "userMenuOpened"
    }],
    staticClass: "user-menu"
  }, [_c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": '/users/' + _vm.$auth.user.id
    }
  }, [_vm._v("\n        Профиль\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/messages"
    }
  }, [_vm._v("\n        Сообщения\n      ")]), _vm._v(" "), _c('hr', {
    staticClass: "user-menu__separator"
  }), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/new"
    }
  }, [_vm._v("\n        Новые книги\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/my"
    }
  }, [_vm._v("\n        Мои книги\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/jailed"
    }
  }, [_vm._v("\n        Пойманные\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/released"
    }
  }, [_vm._v("\n        Отпущенные\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/watch"
    }
  }, [_vm._v("\n        Желаемые\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/user/books/queue"
    }
  }, [_vm._v("\n        В ожидании\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item user-menu__item_add",
    attrs: {
      "to": "/books/registration"
    }
  }, [_vm._v("\n        Добавить книгу\n      ")]), _vm._v(" "), _c('hr', {
    staticClass: "user-menu__separator"
  }), _vm._v(" "), _c('NuxtLink', {
    staticClass: "user-menu__item",
    attrs: {
      "to": "/profile"
    }
  }, [_vm._v("\n        Настройки\n      ")]), _vm._v(" "), _vm.$auth.user.isAdmin ? _c('a', {
    staticClass: "user-menu__item user-menu__item_admin",
    attrs: {
      "target": "_blank",
      "href": 'https://admin.' + (_vm.$config.dev ? 'dev.' : '') + 'bookcrossing.ru/'
    }
  }, [_vm._v("\n        Админка\n      ")]) : _vm._e(), _vm._v(" "), _c('hr', {
    staticClass: "user-menu__separator"
  }), _vm._v(" "), _c('span', {
    staticClass: "user-menu__item user-menu__item_logout",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Выйти")])], 1) : _vm._e(), _vm._v(" "), !_vm.$auth.loggedIn ? _c('div', {
    staticClass: "header-login"
  }, [_c('NuxtLink', {
    staticClass: "header-login__login-link",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('login')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    staticClass: "header-login__registration-link",
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('join')) + "\n      ")])], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }