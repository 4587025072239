var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-container"
  }, [_c('footer', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: {
        callback: _vm.visibilityChanged,
        throttle: 300,
        once: true
      },
      expression: "{callback: visibilityChanged, throttle: 300, once: true}"
    }],
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer__section"
  }, [_c('h4', {
    staticClass: "footer__section-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('about_title')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('about')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/news"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('news')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/countries"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('countries')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/cities"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('cities')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/users"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('users')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/forum"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('forum')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/posters"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('posters')) + "\n      ")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('h4', {
    staticClass: "footer__section-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('books')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/hunt"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('hunt')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/books"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('catalog')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/authors"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('authors')) + "\n      ")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('h4', {
    staticClass: "footer__section-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('shelves')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": '/shelf/city/' + _vm.city.id
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('map')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/shelves/open"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('open')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": '/shelf/add/' + _vm.city.id
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('add')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "footer__section"
  }, [_c('h4', {
    staticClass: "footer__section-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('communities')) + "\n      ")]), _vm._v(" "), _c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "https://vk.com/knigovorot",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v(_vm._s(_vm.$t('vk_russia')))])]), _vm._v(" "), _c('li', [_c('a', {
    attrs: {
      "href": "https://vk.com/club10196767",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v(_vm._s(_vm.$t('vk_ukraine')))])]), _vm._v(" "), _c('li', [_c('a', {
    attrs: {
      "href": "https://vk.com/club7478971",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v(_vm._s(_vm.$t('vk_belarus')))])]), _vm._v(" "), _c('li', [_c('a', {
    attrs: {
      "href": "https://vk.com/club10569162",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v(_vm._s(_vm.$t('vk_kazakhstan')))])]), _vm._v(" "), _c('br'), _vm._v(" "), _vm._m(0), _vm._v(" "), _vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3), _vm._v(" "), _vm._m(4), _vm._v(" "), _vm._m(5), _vm._v(" "), _vm._m(6)])]), _vm._v(" "), _vm.$auth.loggedIn ? _c('div', {
    staticClass: "footer__section"
  }, [_c('h4', {
    staticClass: "footer__section-title"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('menu')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/new"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('new')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/my"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('my')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/jailed"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('jailed')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/released"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('released')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/watch"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('wishlist')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/user/books/queue"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('queue')) + "\n      ")]), _vm._v(" "), _c('br'), _vm._v(" "), _c('NuxtLink', {
    staticClass: "button",
    attrs: {
      "to": "/books/registration"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('register')) + "\n      ")])], 1) : _vm._e(), _vm._v(" "), _vm.displayVk ? _c('div', {
    staticClass: "footer__section"
  }, [_vm.city.vkId ? _c('vk-community', {
    attrs: {
      "id": _vm.city.vkId
    }
  }) : _c('vk-community', {
    attrs: {
      "id": 2438949
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('footer', {
    staticClass: "copyright"
  }, [_c('div', {
    staticClass: "copyright__main"
  }, [_vm._v("\n      ©2004–" + _vm._s(_vm.year) + " " + _vm._s(_vm.$t('copyright')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "copyright__language"
  }, _vm._l(_vm.availableLocales, function (locale) {
    return _c('a', {
      key: locale.code,
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.setLocale(locale.code);
        }
      }
    }, [_vm._v(_vm._s(locale.name))]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://www.instagram.com/knigovorot/",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Instagram")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://www.youtube.com/knigovorot",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("YouTube")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://www.tiktok.com/@knigovorot/",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("TikTok")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://www.facebook.com/knigovorot.ru/",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Facebook")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://t.me/rusbookcrossing",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Telegram")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://twitter.com/bookcrossing_su",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("Twitter")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    attrs: {
      "href": "https://bookcrossing-ru.livejournal.com/",
      "target": "_blank",
      "rel": "noopener"
    }
  }, [_vm._v("LiveJournal")])]);
}]

export { render, staticRenderFns }