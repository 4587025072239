var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('div', {
    staticClass: "header-container"
  }, [_c('pageHeader')], 1), _vm._v(" "), _c('div', {
    ref: "wrapper",
    staticClass: "wrapper"
  }, [_c('nav', {
    staticClass: "navigation"
  }, [_c('NuxtLink', {
    attrs: {
      "to": "/hunt",
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('hunt')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/books",
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('books')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": '/shelf/city/' + _vm.city.id,
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('shelves')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/forum",
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('forum')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/news",
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('news')) + "\n      ")]), _vm._v(" "), _c('NuxtLink', {
    attrs: {
      "to": "/about",
      "active-class": "active"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('about')) + "\n      ")])], 1), _vm._v(" "), _c('div', {
    ref: "content",
    staticClass: "content"
  }, [_c('Nuxt', {
    staticClass: "body"
  })], 1)]), _vm._v(" "), _c('page-footer'), _vm._v(" "), _c('message-notification')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }