//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { directive as onClickaway } from "vue-clickaway";
import { mapState } from "vuex"

export default {
  directives: {
    onClickaway,
  },
  data() {
    return {
      list: [],
      query: "",
      isOpen: false,
      displayConfirmation: false,
      loading: false,
    }
  },
  computed: {
    ...mapState("city", ["city"]),
  },
  mounted() {
    if (!this.$auth.$storage.getLocalStorage("city_confirmed")) {
      this.displayConfirmation = true

      // Do not display multiple times
      this.$auth.$storage.setLocalStorage("city_confirmed", true)
    }
  },

  methods: {
    async select(cityId) {

      let city = null

      if (this.$auth.loggedIn) {
        await this.$axios.$post(
          '/geo/city',
          {
            city_id: cityId
          }
        ).then(response => {
          if (response.success) {
            city = response.city
          }
        })
      } else {
        await this.$axios.$get('/geo/cities/' + cityId).then(response => {
          if (response.success) {
            city = response.city
          }
        })
      }

      this.$cookies.set('cityId', cityId, {
        expires: new Date(new Date().setFullYear((new Date()).getFullYear() + 1))
      });
      this.$cookies.remove('language');
      this.$store.commit('city/setCity', city)
      this.$i18n.setLocale(city.country.language)
      this.isOpen = false;
      this.query = '';
      await this.loadCities();
    },
    async loadCities() {
      this.loading = true;
      return this.$axios.$get(
        '/geo/cities',
        {
          params: {
            query: this.query,
            except: this.$store.state.city.city.id || null,
            limit: 9,
            order: 'shelves'
          }
        }
      ).then(response => {
        this.loading = false
        this.list = response.data;
      })
    },
    open() {
      this.isOpen = true;
      !this.list.length && this.loadCities()
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen ? this.close() : this.open();
    },
    confirm() {
      this.displayConfirmation = false;
    },
    chose() {
      this.displayConfirmation = false;
      this.open()
    }
  },
}
