/**
 * Get meta information
 *
 * @param {Object} options meta tags and socials related like og and twitter
 * @param {String} options.title Page title
 * @param {String= | null} options.description Page description
 * @param {String= | null} options.image Image for social networks
 *
 * @returns {Object} Object for nuxt head()
 */
export default (options) => {
  const meta = [
    {
      hid: "description",
      name: "description",
      content: (options && options.description) || '',
    },
    {
      hid: "og:title",
      property: "og:title",
      content: (options && options.title) || '',
    },
    {
      hid: "og:description",
      property: "og:description",
      content: (options && options.description) || '',
    },
    {
      hid: "og:image",
      property: "og:image",
      content: (options && options.image) || '',
    },
    {
      hid: "twitter:title",
      name: "twitter:title",
      content: (options && options.title) || '',
    },
    {
      hid: "twitter:description",
      name: "twitter:description",
      content: (options && options.description) || '',
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      content: (options && options.image) || '',
    },
  ]

  return {
    title: (options && options.title) || '',
    description: (options && options.description) || '',
    meta,
  }
};
