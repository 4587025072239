const middleware = {}

middleware['fetchCity'] = require('../middleware/fetchCity.js')
middleware['fetchCity'] = middleware['fetchCity'].default || middleware['fetchCity']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.js')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

export default middleware
