import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_7d07f6bc from 'nuxt_plugin_plugin_7d07f6bc' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_7d061a7c from 'nuxt_plugin_sentryserver_7d061a7c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_174ca8f4 from 'nuxt_plugin_sentryclient_174ca8f4' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_pluginutils_937ebafa from 'nuxt_plugin_pluginutils_937ebafa' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_42bac9e4 from 'nuxt_plugin_pluginrouting_42bac9e4' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_257cdb21 from 'nuxt_plugin_pluginmain_257cdb21' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_68787b40 from 'nuxt_plugin_cookieuniversalnuxt_68787b40' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_toast_3446df67 from 'nuxt_plugin_toast_3446df67' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_681692b8 from 'nuxt_plugin_axios_681692b8' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_workbox_3e8d39d4 from 'nuxt_plugin_workbox_3e8d39d4' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_28496ce7 from 'nuxt_plugin_metaplugin_28496ce7' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_yandexmetrikaplugin6dcde39e_32b0e586 from 'nuxt_plugin_yandexmetrikaplugin6dcde39e_32b0e586' // Source: ./yandex-metrika.plugin.6dcde39e.js (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_Fragment_d6d6e834 from 'nuxt_plugin_Fragment_d6d6e834' // Source: ../plugins/Fragment (mode: 'all')
import nuxt_plugin_vueSelect_9900d128 from 'nuxt_plugin_vueSelect_9900d128' // Source: ../plugins/vueSelect (mode: 'all')
import nuxt_plugin_debounce_137023bf from 'nuxt_plugin_debounce_137023bf' // Source: ../plugins/debounce (mode: 'all')
import nuxt_plugin_jsonld_593ee954 from 'nuxt_plugin_jsonld_593ee954' // Source: ../plugins/jsonld (mode: 'all')
import nuxt_plugin_floatingvue_393b2496 from 'nuxt_plugin_floatingvue_393b2496' // Source: ../plugins/floating-vue (mode: 'client')
import nuxt_plugin_onesignal_0549a1d8 from 'nuxt_plugin_onesignal_0549a1d8' // Source: ../plugins/onesignal (mode: 'client')
import nuxt_plugin_editor_6bfb673a from 'nuxt_plugin_editor_6bfb673a' // Source: ../plugins/editor (mode: 'client')
import nuxt_plugin_numberFormat_55f772ac from 'nuxt_plugin_numberFormat_55f772ac' // Source: ../plugins/filters/numberFormat (mode: 'all')
import nuxt_plugin_pluralize_6219e4f4 from 'nuxt_plugin_pluralize_6219e4f4' // Source: ../plugins/filters/pluralize (mode: 'all')
import nuxt_plugin_ymapPlugin_43baa32b from 'nuxt_plugin_ymapPlugin_43baa32b' // Source: ../plugins/ymapPlugin.js (mode: 'client')
import nuxt_plugin_plugin_e1ba9418 from 'nuxt_plugin_plugin_e1ba9418' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_echo_99e0b44a from 'nuxt_plugin_echo_99e0b44a' // Source: ./echo.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ru"},"title":"Книговорот :: Буккроссинг","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, shrink-to-fit=no"},{"hid":"description","name":"description","content":"Освободите книги! Превратим весь мир в библиотеку!"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#ffffff"},{"property":"og:locale","content":"ru_RU"},{"property":"og:site_name","content":"Буккроссинг"},{"property":"og:type","content":"website"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"images\u002Fappicons\u002Fapple-touch-icon.png"},{"rel":"icon","href":"images\u002Fappicons\u002Ffavicon.svg"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"images\u002Fappicons\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"images\u002Fappicons\u002Ffavicon-16x16.png"},{"rel":"manifest","href":"\u002Fsite.webmanifest"},{"rel":"mask-icon","href":"\u002Fimages\u002Fappicons\u002Fsafari-pinned-tab.svg","color":"#f2c94c"},{"rel":"sitemap","href":"https:\u002F\u002Fapi.bookcrossing.ru\u002Fsitemap.xml","type":"application\u002Fxml"},{"rel":"alternate","href":"https:\u002F\u002Fapi.bookcrossing.ru\u002Ffeeds\u002Fforum","type":"application\u002Fatom+xml","title":"Новые темы на форуме"},{"rel":"alternate","href":"https:\u002F\u002Fapi.bookcrossing.ru\u002Ffeeds\u002Fnews","type":"application\u002Fatom+xml","title":"Новости буккроссинга"},{"href":"https:\u002F\u002Fmc.yandex.ru\u002Fmetrika\u002Ftag.js","rel":"preload","as":"script"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_7d07f6bc === 'function') {
    await nuxt_plugin_plugin_7d07f6bc(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_7d061a7c === 'function') {
    await nuxt_plugin_sentryserver_7d061a7c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_174ca8f4 === 'function') {
    await nuxt_plugin_sentryclient_174ca8f4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_937ebafa === 'function') {
    await nuxt_plugin_pluginutils_937ebafa(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_42bac9e4 === 'function') {
    await nuxt_plugin_pluginrouting_42bac9e4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_257cdb21 === 'function') {
    await nuxt_plugin_pluginmain_257cdb21(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_68787b40 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_68787b40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_3446df67 === 'function') {
    await nuxt_plugin_toast_3446df67(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_681692b8 === 'function') {
    await nuxt_plugin_axios_681692b8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_3e8d39d4 === 'function') {
    await nuxt_plugin_workbox_3e8d39d4(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_28496ce7 === 'function') {
    await nuxt_plugin_metaplugin_28496ce7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_yandexmetrikaplugin6dcde39e_32b0e586 === 'function') {
    await nuxt_plugin_yandexmetrikaplugin6dcde39e_32b0e586(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_Fragment_d6d6e834 === 'function') {
    await nuxt_plugin_Fragment_d6d6e834(app.context, inject)
  }

  if (typeof nuxt_plugin_vueSelect_9900d128 === 'function') {
    await nuxt_plugin_vueSelect_9900d128(app.context, inject)
  }

  if (typeof nuxt_plugin_debounce_137023bf === 'function') {
    await nuxt_plugin_debounce_137023bf(app.context, inject)
  }

  if (typeof nuxt_plugin_jsonld_593ee954 === 'function') {
    await nuxt_plugin_jsonld_593ee954(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_floatingvue_393b2496 === 'function') {
    await nuxt_plugin_floatingvue_393b2496(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_onesignal_0549a1d8 === 'function') {
    await nuxt_plugin_onesignal_0549a1d8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_editor_6bfb673a === 'function') {
    await nuxt_plugin_editor_6bfb673a(app.context, inject)
  }

  if (typeof nuxt_plugin_numberFormat_55f772ac === 'function') {
    await nuxt_plugin_numberFormat_55f772ac(app.context, inject)
  }

  if (typeof nuxt_plugin_pluralize_6219e4f4 === 'function') {
    await nuxt_plugin_pluralize_6219e4f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ymapPlugin_43baa32b === 'function') {
    await nuxt_plugin_ymapPlugin_43baa32b(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_e1ba9418 === 'function') {
    await nuxt_plugin_plugin_e1ba9418(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_99e0b44a === 'function') {
    await nuxt_plugin_echo_99e0b44a(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
