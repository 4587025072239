export const state = () => ({
  period: 'day',
  statistic: {
    jailed: {
      day: 0,
      month: 0,
      year: 0
    },
    released: {
      day: 0,
      month: 0,
      year: 0
    },
    shelves: 0,
    shelvesTotal: 0,
  },
})

export const actions = {
  async getCounts({ commit }, cityId) {
    try {
      await this.$axios.$get('/stats/counts', {
        params: {
          cityId
        }
      }).then(response => {
        this.loading = false;
        commit('SET_PERIOD', response.data);
      });
    } catch (e) {
      throw new Error(e)
    }
  },
}

export const mutations = {
  SET_PERIOD(state, payload) {
    state.statistic = payload
  },
}
