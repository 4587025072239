export default async function({ app, $axios, store, req }) {
  if (store.state.city.city) {
    return;
  }

  const ip = process.server ? req?.connection?.remoteAddress : null;
  const savedCityId = app.$cookies.get('cityId');
  /** @type ICityExtended */
  let city = null;

  if (savedCityId) {
    await $axios.$get(
      '/geo/cities/' + savedCityId
    ).then(response => {
      city = response.city
    })
  }

  if (!city) {
    await $axios.$get(
      '/geo/city',
      {
        headers: {
          'X-Forwarded-For': ip,
        }
      }
    ).then(response => {
      city = response.city
      app.$cookies.set('cityId', city.id, {
        expires: new Date(new Date().setFullYear((new Date()).getFullYear() + 1))
      });
    })
  }

  store.commit('city/setCity', city)
}
