import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _75227961 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _215bf167 = () => interopDefault(import('../pages/bookcrossing.vue' /* webpackChunkName: "pages/bookcrossing" */))
const _beb071f0 = () => interopDefault(import('../pages/countries.vue' /* webpackChunkName: "pages/countries" */))
const _778133b8 = () => interopDefault(import('../pages/forum/index.vue' /* webpackChunkName: "pages/forum/index" */))
const _376cdcf4 = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _5e81f73d = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _627e5ab0 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _7fefc9f2 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _101284dc = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _7de41b06 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _6bbd7e3c = () => interopDefault(import('../pages/restore.vue' /* webpackChunkName: "pages/restore" */))
const _e7190042 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _73fcff87 = () => interopDefault(import('../pages/forum/add.vue' /* webpackChunkName: "pages/forum/add" */))
const _24641cb2 = () => interopDefault(import('../pages/forum/search.vue' /* webpackChunkName: "pages/forum/search" */))
const _826d3cd6 = () => interopDefault(import('../pages/forum/topic.vue' /* webpackChunkName: "pages/forum/topic" */))
const _26b7b790 = () => interopDefault(import('../pages/shelf/add.vue' /* webpackChunkName: "pages/shelf/add" */))
const _b06316e8 = () => interopDefault(import('../pages/shelf/city.vue' /* webpackChunkName: "pages/shelf/city" */))
const _61143a54 = () => interopDefault(import('../pages/user/books/jailed.vue' /* webpackChunkName: "pages/user/books/jailed" */))
const _2239da6e = () => interopDefault(import('../pages/user/books/my.vue' /* webpackChunkName: "pages/user/books/my" */))
const _04289453 = () => interopDefault(import('../pages/user/books/new.vue' /* webpackChunkName: "pages/user/books/new" */))
const _d1c56ef8 = () => interopDefault(import('../pages/user/books/queue.vue' /* webpackChunkName: "pages/user/books/queue" */))
const _005b761a = () => interopDefault(import('../pages/user/books/released.vue' /* webpackChunkName: "pages/user/books/released" */))
const _23de357c = () => interopDefault(import('../pages/user/books/watch.vue' /* webpackChunkName: "pages/user/books/watch" */))
const _12b8c420 = () => interopDefault(import('../pages/forum/_id.vue' /* webpackChunkName: "pages/forum/_id" */))
const _7e3928aa = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _384dee07 = () => interopDefault(import('../pages/users/_id.vue' /* webpackChunkName: "pages/users/_id" */))
const _d64b57b4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _bb6e4346 = () => interopDefault(import('../views/_.vue' /* webpackChunkName: "" */))
const _fa74cff6 = () => interopDefault(import('../views/hunt.vue' /* webpackChunkName: "" */))
const _1ee09570 = () => interopDefault(import('../views/books/registration.vue' /* webpackChunkName: "" */))
const _3f3e6252 = () => interopDefault(import('../views/_share.vue' /* webpackChunkName: "" */))
const _162af7c2 = () => interopDefault(import('../views/_reset.vue' /* webpackChunkName: "" */))
const _48084fb2 = () => interopDefault(import('../views/_regions.vue' /* webpackChunkName: "" */))
const _54f5b534 = () => interopDefault(import('../views/_cities.vue' /* webpackChunkName: "" */))
const _006f8d46 = () => interopDefault(import('../views/authors.vue' /* webpackChunkName: "" */))
const _8778cab0 = () => interopDefault(import('../views/_author.vue' /* webpackChunkName: "" */))
const _2ade9703 = () => interopDefault(import('../views/shelves/open.vue' /* webpackChunkName: "" */))
const _42875cc1 = () => interopDefault(import('../views/shelves/shelf.vue' /* webpackChunkName: "" */))
const _36df0f9c = () => interopDefault(import('../views/books/catalog.vue' /* webpackChunkName: "" */))
const _93e3e38a = () => interopDefault(import('../views/cities.vue' /* webpackChunkName: "" */))
const _17925ea0 = () => interopDefault(import('../views/books/book.vue' /* webpackChunkName: "" */))
const _3968bac4 = () => interopDefault(import('../views/posters.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _75227961,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/bookcrossing",
    component: _215bf167,
    pathToRegexpOptions: {"strict":true},
    name: "bookcrossing"
  }, {
    path: "/countries",
    component: _beb071f0,
    pathToRegexpOptions: {"strict":true},
    name: "countries"
  }, {
    path: "/forum",
    component: _778133b8,
    pathToRegexpOptions: {"strict":true},
    name: "forum"
  }, {
    path: "/join",
    component: _376cdcf4,
    pathToRegexpOptions: {"strict":true},
    name: "join"
  }, {
    path: "/login",
    component: _5e81f73d,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/messages",
    component: _627e5ab0,
    pathToRegexpOptions: {"strict":true},
    name: "messages"
  }, {
    path: "/news",
    component: _7fefc9f2,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/privacy",
    component: _101284dc,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/profile",
    component: _7de41b06,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/restore",
    component: _6bbd7e3c,
    pathToRegexpOptions: {"strict":true},
    name: "restore"
  }, {
    path: "/users",
    component: _e7190042,
    pathToRegexpOptions: {"strict":true},
    name: "users"
  }, {
    path: "/forum/add",
    component: _73fcff87,
    pathToRegexpOptions: {"strict":true},
    name: "forum-add"
  }, {
    path: "/forum/search",
    component: _24641cb2,
    pathToRegexpOptions: {"strict":true},
    name: "forum-search"
  }, {
    path: "/forum/topic",
    component: _826d3cd6,
    pathToRegexpOptions: {"strict":true},
    name: "forum-topic"
  }, {
    path: "/shelf/add",
    component: _26b7b790,
    pathToRegexpOptions: {"strict":true},
    name: "shelf-add"
  }, {
    path: "/shelf/city",
    component: _b06316e8,
    pathToRegexpOptions: {"strict":true},
    name: "shelf-city"
  }, {
    path: "/user/books/jailed",
    component: _61143a54,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-jailed"
  }, {
    path: "/user/books/my",
    component: _2239da6e,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-my"
  }, {
    path: "/user/books/new",
    component: _04289453,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-new"
  }, {
    path: "/user/books/queue",
    component: _d1c56ef8,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-queue"
  }, {
    path: "/user/books/released",
    component: _005b761a,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-released"
  }, {
    path: "/user/books/watch",
    component: _23de357c,
    pathToRegexpOptions: {"strict":true},
    name: "user-books-watch"
  }, {
    path: "/forum/:id",
    component: _12b8c420,
    pathToRegexpOptions: {"strict":true},
    name: "forum-id"
  }, {
    path: "/news/:slug",
    component: _7e3928aa,
    pathToRegexpOptions: {"strict":true},
    name: "news-slug"
  }, {
    path: "/users/:id",
    component: _384dee07,
    pathToRegexpOptions: {"strict":true},
    name: "users-id"
  }, {
    path: "/",
    component: _d64b57b4,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "*",
    component: _bb6e4346,
    name: "custom"
  }, {
    path: "/forum/topic/:id",
    component: _826d3cd6,
    name: "topic"
  }, {
    path: "/forum/:id/add",
    component: _73fcff87,
    name: "addTopic"
  }, {
    path: "/hunt",
    component: _fa74cff6,
    name: "hunt"
  }, {
    path: "/messages/:id",
    component: _627e5ab0,
    name: "messagesPage"
  }, {
    path: "/shelf/city/:id/:title?",
    component: _b06316e8,
    name: "shelfCity"
  }, {
    path: "/shelf/add/:id",
    component: _26b7b790,
    name: "newShelf"
  }, {
    path: "/books/registration",
    component: _1ee09570,
    name: "registerBook"
  }, {
    path: "/books/:id/share",
    component: _3f3e6252,
    name: "shareImage"
  }, {
    path: "/bookcrossing/show/:bcid/:sid",
    component: _215bf167,
    name: "showBookcrossing"
  }, {
    path: "/reset/:token",
    component: _162af7c2,
    name: "resetPassword"
  }, {
    path: "/countries/:id",
    component: _48084fb2,
    name: "regions"
  }, {
    path: "/regions/:id",
    component: _54f5b534,
    name: "citiesInRegion"
  }, {
    path: "/authors",
    component: _006f8d46,
    name: "authors"
  }, {
    path: "/authors/:slug",
    component: _8778cab0,
    name: "author"
  }, {
    path: "/shelves/open",
    component: _2ade9703,
    name: "openShelf"
  }, {
    path: "/shelves/:id",
    component: _42875cc1,
    name: "shelf"
  }, {
    path: "/books",
    component: _36df0f9c,
    name: "catalog"
  }, {
    path: "/cities",
    component: _93e3e38a,
    name: "cities"
  }, {
    path: "/books/:id",
    component: _17925ea0,
    name: "showBook"
  }, {
    path: "/posters",
    component: _3968bac4,
    name: "posters"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
