export const state = () => ({
  /** @type ICityExtended */
  city: null
})

export const mutations = {
  setCity(state, city) {
    state.city = city
  },
}
