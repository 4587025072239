//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CitySelector from "components/citySelector";
import DownIcon from "assets/svg/triangle-down.svg?inline";
import Logo from "assets/svg/logo.svg?inline";

export default {
  components: {
    CitySelector,
    DownIcon,
    Logo,
  },
  data() {
    return {
      searchQuery: "",
      userMenuOpened: false
    };
  },
  methods: {
    logout() {
      this.$auth.logout();
    },
    handleSearch() {
      let matches = this.searchQuery.match(/^(\d{3})-(\d+)$/);
      if (!matches) {
        matches = this.searchQuery.match(/^(\d{5,})$/)
      }
      if (matches) {
        this.searchQuery = '';
        return this.$router.push("/bookcrossing/show/" + matches[2] + "/" + matches[1]);
      }
      this.$router.push({
        path: '/books',
        query: {
          search_text: this.searchQuery
        }
      });
      this.searchQuery = '';
    }
  }
};
