export const state = () => ({
  items: [],
  displayPhotoModal: false,
  selectedPhoto: null,
})

export const mutations = {
  setItems(state, items) {
    state.items = items
  },
  SET_DISPLAY_PHOTO_MODAL(state, displayPhotoModal) {
    state.displayPhotoModal = displayPhotoModal
  },
  SET_SELECTED_PHOTO(state, selectedPhoto) {
    state.selectedPhoto = selectedPhoto
  },
}
