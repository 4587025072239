import Url from "url-parse"

/**
 * @typedef {Object} TRedirects
 * @property {string} from - Regexp to match url
 * @property {string|Function} to - url to redirect, or function with url result
 */

export default function(req, res, next) {
  const url = new Url(req.url)
  const path = url.pathname.replace(/^\/+/, '/')
  const query = new URLSearchParams(url.query)

  /**
   * @type {TRedirects[]}
   */
  const redirects = [
    {
      // language=JSRegexp
      from: "/forum/viewtopic\.php",
      to() {
        if (query.has("t") && /^\d+$/.test(query.get("t"))) {
          return "/forum/topic/" + query.get("t")
        }
        return "/forum"
      },
    },
    {
      // language=JSRegexp
      from: "^/shelf/?$",
      to: "/countries",
    },
    {
      // language=JSRegexp
      from: "^/countries/city/(\\d+)$",
      to: "/shelf/city/$1",
    },
    {
      // language=JSRegexp
      from: "/news/archive",
      to: "/news",
    },
    {
      // language=JSRegexp
      from: "/page/about",
      to: "/about",
    },
    {
      // language=JSRegexp
      from: "/user/registration/show",
      to: "/join",
    },
    {
      // language=JSRegexp
      from: "/forum/privmsg\.php",
      to: "/messages",
    },
    {
      // language=JSRegexp
      from: "/forum/index\.php",
      to: "/forum",
    },
    {
      // language=JSRegexp
      from: "^/catalog/?(index)?$",
      to: "/books",
    },
    {
      // language=JSRegexp
      from: "^/bookcrosser/show/(\\d+)/?(.*)$",
      to: "/users/$1",
    },
    {
      // language=JSRegexp
      from: "/books/category/(\\d+)/?$",
      to: "/books/",
    },
    {
      // language=JSRegexp
      from: "/book/show/(\\d+)/?(.*)",
      to: "/books/$1",
    },
    {
      // language=JSRegexp
      from: "/book/registration",
      to: "/books/registration",
    },
    {
      // language=JSRegexp
      from: "/statistic/index",
      to: "/hunt",
    },
    {
      // language=JSRegexp
      from: "/statistic/cities",
      to: "/cities",
    },
    {
      // language=JSRegexp
      from: "/booklist\.php",
      to: "/hunt",
    },
    {
      // language=JSRegexp
      from: "/shelf\.php",
      to: "/shelf/city",
    },
    {
      // language=JSRegexp
      from: "/logo\.php",
      to: "/posters",
    },
  ]

  const redirect = redirects.find((r) => new RegExp(r.from).test(path))
  if (!redirect) {
    return next()
  }

  const newPath = path.replace(new RegExp(redirect.from), redirect.to);

  res.writeHead(
    301,
    {
      Location: typeof redirect.to === "function" ? redirect.to() : newPath,
    },
  )
  res.end()
}
