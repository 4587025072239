var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "error-page"
  }, [_vm.notFound ? [_c('h1', {
    staticClass: "error-page__title"
  }, [_vm._v("\n      " + _vm._s(_vm.error.message) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "error-page__message"
  }, [_vm._v("\n      Страница была удалена с сайта или вы перешли по неправильной ссылке"), _c('br'), _vm._v(" "), _c('nuxt-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("\n        Перейдите на главную\n      ")]), _vm._v(" или воспользуйтесь ссылками ниже\n    ")], 1), _vm._v(" "), _c('illustration', {
    staticClass: "error-page__illustration"
  })] : [_c('h1', {
    staticClass: "error-page__title"
  }, [_vm._v("\n      Возникла ошибка\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "error-page__message"
  }, [_vm._v("\n      " + _vm._s(_vm.error.message) + "\n    ")])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }